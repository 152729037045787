import footerlogo from '../assets/footerlogo.png'
import css from './css/footer.module.scss'
import { useViewport } from '../useViewPort'
import { getSiteInfo } from '../axios/index'
import { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";


// 扩展企业链接组件
const Kuozan = () => {
  const htmlList = [
    {
      name: '新闻动态',
      child: [
        { name: '企业新闻', router: '/news?id=135' },
        { name: '行业动态', router: '/news?id=136' },
        { name: '公告信息', router: '/news?id=137' },
      ]
    },
    {
      name: '产品服务',
      child: [
        { name: 'MiniLED', router: '/services?id=138' },
        { name: 'MicroLED', router: '/services?id=139' },
        { name: 'ODM产品', router: '/services?id=151' },
        { name: '其他', router: '/services?id=140' },
      ]
    },
    {
      name: '关于利晶',
      child: [
        { name: "企业简介", router: '/about?id=0' },
        { name: "荣誉资质", router: '/about?id=1' },
        { name: "招聘英才", router: '/about?id=2' },
        { name: '联系我们', router: '/about?id=3' },
      ]
    }
  ]
  const history = useHistory()
  return (
    <div className={css.kuozan}>
      {
        htmlList.map((item, index) => (
          <div key={index}>
            <div className={css.title2}>{item.name}</div>
            {
              item.child.map((val, i) => (
                <div
                  key={i}
                  className={css.listItem}
                  onClick={() => history.push(val.router)}>
                  {val.name}
                </div>
              ))
            }
          </div>
        ))
      }
    </div>
  )
}

// 公司简介
export const MainHx = (props) => {
  const { info } = props
  return (
    <div className={css.mainHx}>
      <div className={css.titie}>
        <div>{info.name}</div>
      </div>
      <div className={css.jianjie}>
        <img src={footerlogo} alt="img" className={css.img} />
        <div>
          <div className={css.address}>
            <div className={css.text}>公司地址</div>
            <div className={css.text2}>
              <div className="mb">{info.address ? info.address.split('-')[0] : ''}</div>
              <div>{info.address ? info.address.split('-')[1] : ''}</div>
            </div>
          </div>
          <div className={css.phone}>
            <div className={css.text}>客服热线</div>
            <div className={css.phoneNum}>{info.phone}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

// 底部组件
const Footer = () => {
  const { width } = useViewport()
  const [info, setInfo] = useState({})
  useEffect(() => {
    getSiteInfo().then(res => setInfo(res.data))
  }, [])

  return (
    <div className={css.footer}>
      <div className={css.main}>
        <MainHx info={info} />
        {width > 875 ? <Kuozan info={info} /> : null}
      </div>
      <div className={css.main}>
          <div className={css.right}><a href="https://beian.miit.gov.cn/">{info.record}</a>|{info.powerby}</div>
        </div>
    </div>
  )
}

export default Footer
