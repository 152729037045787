import css from '../css/hezuo.module.scss'
import icon from '../../../assets/home/icon3.png'
import iconLeft from '../../../assets/image/iconLeft.png'
import iconRight from '../../../assets/image/iconRight.png';

import partnerleyard from '../../../assets/partner/leyard.jpeg'
import partnerEpistar from '../../../assets/partner/epistar.jpeg'
import partnerTclcsot from '../../../assets/partner/tclcsot.jpeg'
import partnerLongda from '../../../assets/partner/longda.jpeg'
import partnerHikvision from '../../../assets/partner/hikvision.jpeg'
import partnerCetc from '../../../assets/partner/cetc.jpeg'
import partnerHuawei from '../../../assets/partner/huawei.jpg'
import partnerXiaomi from '../../../assets/partner/xiaomi.jpg'
import partnerLenovo from '../../../assets/partner/lenovo.png'
import partnerGjnyjt from '../../../assets/partner/gjnyjt.jpg'

const Hezuo = ()=>{
  return(
    <div className={css.hezuo} id="offsetTop4">
      <div className={css.title} style={{fontWeight:'bold'}}>
        <div className={css.titles}>
          <img src={iconLeft} alt="iconLeft" className={css.iconLeft}/>
          <div id="div0" style={{margin: '0 10px 10px'}}>合作伙伴</div>
          <img src={iconRight} alt="iconRight" className={css.iconRight}/>
        </div>
        <div className={css.titleBottom}>
          <div className={css.b1}></div>
          <div className={css.b2}></div>
        </div>
      </div>
      <div className={css.text}>利晶微电子期望与更多企业展开友好合作，为电子LED行业提供更加专业且多元化解决方案</div>
      <div className={css.imgs}>
        <a href="/">
          <img src={partnerleyard} alt=""/>
        </a>
        <a href="/">
          <img src={partnerEpistar} alt=""/>
        </a>
        <a href="/">
          <img src={partnerTclcsot} alt=""/>
        </a>
        <a href="/">
          <img src={partnerLongda} alt=""/>
        </a>
        <a href="/">
          <img src={partnerHikvision} alt=""/>
        </a>
        <a href="/">
          <img src={partnerCetc} alt=""/>
        </a>
        <a href="/">
          <img src={partnerGjnyjt} alt=""/>
        </a>
        <a href="/">
          <img src={partnerHuawei} alt=""/>
        </a>
        <a href="/">
          <img src={partnerXiaomi} alt=""/>
        </a>
        <a href="/">
          <img src={partnerLenovo} alt=""/>
        </a>
      </div>
      <img src={icon} alt="icon" className={css.icon}/>
    </div>
  )
}

export default Hezuo
