import React, { Component } from 'react'
const AMap = window.AMap;
export default class map extends Component {
    constructor(props) {
        super(props)
        this.state = {
            rmap: null,
        }
    }
    // 组建挂载之后执行
    componentDidMount() {
        // 初始化地图
        this.rmap = new AMap.Map('container', {
            resizeEnable: true,
            center: [120.261434,31.625764],
            zoom: 15,
            mapStyle: 'amap://styles/1ea209612f0612528d7da89fe0f731d0',
        });
        let marker = new AMap.Marker({
            position: [120.261434,31.625764]//位置
        })
        // map.add(marker);
        marker.setMap(this.rmap);
    }
    render() {
        return (
            <div id="container" style={{ width: "100vw", height: "404px",padding:'12px',boxSizing:'border-box'}}></div>
        )
    }
}
