import css from '../css/shiye.module.scss'
import bg1 from '../../../assets/bg1.png'
import bg2 from '../../../assets/bg2.png'
import bg3 from '../../../assets/bg3.png'
import bg4 from '../../../assets/bg4.png'
import bg5 from '../../../assets/bg5.png'
import iconLeft from '../../../assets/image/iconLeft.png'
import iconRight from '../../../assets/image/iconRight.png';

const ShiyeMobile = () => {
  return (
    <div className={css.ShiyeMobile}>
      <div className={css.title}>
        <div className={css.titles}>
          <img src={iconLeft} alt="iconLeft" className={css.iconLeft}/>
          <div id="div0" style={{fontSize:"16px",margin: '0 10px 10px'}}>利晶产品</div>
          <img src={iconRight} alt="iconRight" className={css.iconRight}/>
        </div>
        <div className={css.titleBottom}>
          <div className={css.b1}></div>
          <div className={css.b2}></div>
        </div>
      </div>
      <div className={css.shiyeBox}>
        <img src={bg1} alt=''></img>
        <div className={css.text}>智能显示</div>
        <img src={bg2} alt=''></img>
        <div className={css.text}>芯片制造</div>
        <img src={bg3} alt=''></img>
        <div className={css.text}>智慧照明</div>
        <img src={bg4} alt=''></img>
        <div className={css.text}>文化旅游</div>
        <img src={bg5} alt=''></img>
        <div className={css.text}>VR娱乐</div>
      </div>
    </div>
  )
}
export default ShiyeMobile
