import React from 'react';
import ReactDOM from 'react-dom';
import App from './router';
import './style/main.scss'
// swiper css
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';

import { ViewportProvider } from './useViewPort'

ReactDOM.render(
  <ViewportProvider>
    <App />
  </ViewportProvider>,
  document.getElementById('root')
);
