import css from '../css/shiye.module.scss'
import bg1 from '../../../assets/bg1.png'
import bg2 from '../../../assets/bg2.png'
import bg3 from '../../../assets/bg3.png'
import bg4 from '../../../assets/bg4.png'
import bg5 from '../../../assets/bg5.png'
import iconLeft from '../../../assets/image/iconLeft.png'
import iconRight from '../../../assets/image/iconRight.png';

const Shiye = () => {
  return (
    <div className={css.shiye} id='offsetTop1'>
      <div className={css.title}>
        <div className={css.titles}>
          <img src={iconLeft} alt="iconLeft" className={css.iconLeft}/>
          <div id="div0" style={{margin: '0 10px 10px'}}>利晶产品</div>
          <img src={iconRight} alt="iconRight" className={css.iconRight}/>
        </div>
        <div className={css.titleBottom}>
          <div className={css.b1}></div>
          <div className={css.b2}></div>
        </div>
      </div>
      <div className={css.shiyeBox}>
        <a href="/">
          <div style={{width: '400px', height: "510px"}}>
            <img src={bg1} alt=''></img>
          </div>
        </a>
        <div style={{marginLeft: '6px'}}>
          <a href="/">
            <div style={{width: '440px', height: '258px', marginRight: '6px'}}>
              <img src={bg2} alt=''></img>
            </div>
          </a>
          <a href="/">
            <div style={{width: '340px', height: "258px"}}>
              <img src={bg3} alt=''></img>
            </div>
          </a>
          <a href="/">
            <div style={{width: '210px', height: '240px', marginTop: '6px', marginRight: "6px"}}>
              <img src={bg4} alt=''></img>
            </div>
          </a>
          <a href="/">
            <div style={{width: '570px', height: "240px", marginTop: "6px"}}>
              <img src={bg5} alt=''></img>
            </div>
          </a>
        </div>
      </div>
    </div>
  )
}
export default Shiye
