import css from "../css/about.module.scss"
import { about1 } from "../../../axios"
import { useEffect, useState } from "react"
import Header from "./header";

const About1 = () => {
    const [article, setArticle] = useState([])
    useEffect(() => {
        about1().then(res => {
            const temp = res.data[0].content
            setArticle(temp)
        })
    }, [])

    return (
      <>
          <Header ctit="企业简介" etit="Profile"/>
          <div className={css.con1} dangerouslySetInnerHTML={{ __html: article }}></div>
      </>
    )
}
export default About1
