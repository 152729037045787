import css from './css/services.module.scss'
import {useState, useEffect} from 'react'
import {articleList} from "../../axios";
import {useLocation} from "react-router-dom";

const navList = [
  {name: 'MiniLED', id: "138"},
  {name: 'MicroLED', id: "139",},
  {name: 'ODM产品', id: "151",},
  {name: '其他', id: "140",},
]

const Main = (props) => {
  const {list} = props
  const [content,setContent] = useState(null)

  useEffect(()=>{
    if(list.length > 0){
      setContent(list[0].content)
    }
  },[list])

  return (
    <div className={css.con} dangerouslySetInnerHTML={{ __html: content }}></div>
  )
}

const Services = () => {
  let location = useLocation();
  const [navIndex, setNavIndex] = useState(138)
  const [list, setList] = useState([])

  const changeNav = (navIndex) => {
    setNavIndex(navIndex)
    articleList({
      channelId: navIndex,
      limit: 5,
      page: 1
    }).then(res => {
      setList(res.data)
    })
  }

  useEffect(() => {
    const id = location.search ? location.search.split('?id=')[1] : "138"
    changeNav(id)
  }, [location])

  return (
    <div>
      <div className={css.head} style={{
        background: `url(/banner.png)`
      }}>
        <div className={css.navs}>
          <div className={css.nav}>
            {
              navList.map(item => (
                <div key={item.id}
                     className={navIndex === item.id ? css.active : css.navItem}
                     onClick={() => changeNav(item.id)}>
                  <div>{item.name}</div>
                </div>
              ))
            }
          </div>
        </div>
        <div className={css.bannerTitle}>
          <div className={css.slideInEllipticLeftFwd}>
            <p>
              <span className={css.p1span1}>产品服务</span>
              <span className={css.p1span2}>Product</span>
            </p>
            <p className={css.p2}></p>
          </div>
          <p className={`${css.p3} ${css.slideInEllipticRightBck}`}>利晶微电子在电子信息化领域应用经验丰富，为客户提供稳定可靠的产品和服务</p>
        </div>
      </div>
      <Main list={list}/>
    </div>
  )
}




export default Services
