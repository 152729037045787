import styles from '../css/newsListMobile.module.scss'
import {useHistory} from "react-router-dom";

function NewsListBobile({list}) {
  const history = useHistory()
  return (
    <div className={styles.container}>
      {
        list.map((item, index) => (
          <div key={index} className={styles.item} onClick={() => history.push('/alldetail/' + item.id)}>
            <div className={styles.title}>{item.title}</div>
            <div className={styles.time}>{item.updateDate && item.updateDate.slice(0, 10)}</div>
            <div className={styles.content}>{item.text}</div>
          </div>
        ))
      }
    </div>
  )

}

export default NewsListBobile
