
const Fenge = ()=>{
  return (
    <div className="fenge" id='offsetTop3'>
      <div className="title">无往不“利”  “晶”益求精</div>
      <div className="text">
          利晶微电子将深耕新型显示行业，推动Mini LED和Micro LED显示技术在设计、生产、销售等整条“产业链”的发展，创造MicroLED行业的“技术第一、产能第一、市场占有率第一”！
      </div>
      <div className="fenge-box">
        <div className="item">
          <div style={{fontSize: '24px',marginBottom: '40px'}}>2020年</div>
          <div style={{fontSize: '15px'}}>始建于</div>
        </div>
        <div className="item">
          <div style={{fontSize: '24px',marginBottom: '40px'}}>200人</div>
          <div style={{fontSize: '15px'}}>现有员工</div>
        </div>
        <div className="item">
          <div style={{fontSize: '24px',marginBottom: '40px'}}>10+</div>
          <div style={{fontSize: '15px'}}>专利数</div>
        </div>
      </div>
    </div>
  )
}

export default Fenge
