import css from "../css/nav.module.scss"
const NavTitle = () => {
    return (
        <div className={css.Navheader} style={{
            backgroundImage:`url('/banner2.png')`
        }}>
            <div className={css.bannerTitle}>
                <div className={css.slideInEllipticLeftFwd}>
                    <p>
                        <span className={css.p1span1}>最新动态</span>
                        <span className={css.p1span2}>LATEST NEWS </span>
                    </p>
                    <p className={css.p2}></p>
                </div>
                <p className={`${css.p3} ${css.slideInEllipticRightBck}`}>记录点滴进步 书写精彩篇章  利晶与你一同见证</p>
            </div>
        </div>
    )
}
export default NavTitle
