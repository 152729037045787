import css3 from "../css/about3.module.scss"
const AboutHeader = (props) => {
    return (
        <div className={css3.about3}>
            <div className={css3.tit}>
                <span className={css3.etext}>{props.etit}</span>
                <span className={css3.ctext}>{props.ctit}</span>
            </div>
            <p className={css3.wenzi}>
                {props.text}
            </p>
        </div>

    )
}
export default AboutHeader