import Detail from "../pages/news/components/detail"
import NavTitle from "../pages/news/components/navTitle"


const Item = () => {
  return (
    <div>
      <NavTitle/>
      <Detail/>
    </div>
  )
}
export default Item
