import styles from '../css/navsMobile.module.scss'
import Right from '../../../assets/news/right.png'
import Menu from '../../../assets/news/menu.png'
import {useState,useEffect} from 'react'

const navList = [
  {name: '企业新闻', text: 'Company news', id: "135"},
  {name: '行业动态', text: 'Industry News', id: "136"},
  {name: '公告信息', text: 'Announcement', id: "137"},
]

function NavsMobile({navIndex, changeNav}) {
  const [show, setShow] = useState(false)
  const [active, setActive] = useState({})

  useEffect(()=>{
    const temp = navList.find(nav => nav.id === navIndex)
    setActive(temp)
  },[navIndex])

  return <div className={styles.container}>
    <div key={active.id} className={[styles.item, styles.active].join(' ')} onClick={() => setShow(!show)}>
      <span>{active.name}</span>
      <span>{active.text}</span>
      <img src={Menu} alt=""/>
    </div>
    {
      show ? navList.filter(item => item.id !== navIndex).map(nav => (
        <div key={nav.id} className={styles.item} onClick={() => {
          changeNav(nav.id)
          setShow(false)
        }}>
          <span>{nav.name}</span>
          <span>{nav.text}</span>
          <img src={Right} alt=""/>
        </div>
      )) : null
    }
  </div>
}

export default NavsMobile
