import axios from "axios";

const http = axios.create({
  baseURL:'/api',
  timeout: 100000,
  withCredentials: true
})

const allPendingRequestsRecord = [];
// const pending = {};
function removeAllPendingRequestsRecord() {
  allPendingRequestsRecord && allPendingRequestsRecord.forEach((func) => {
    func('page changes'); // 取消请求
  });
  allPendingRequestsRecord.splice(0); // 移除所有记录
}

export const getConfirmation = (message, callback) => {
  removeAllPendingRequestsRecord();
  console.log(1);
  callback(true);
};

// 取消重复请求
// const removePending = (key, isRequest = false) => {
//   if (pending[key] && isRequest) {
//     pending[key]('取消重复请求');
//   }
//   delete pending[key];
// };

// 添加请求拦截器
http.interceptors.request.use(config => {
  // const reqData = config.url + config.params?.channelId
  // 在请求发送前执行一下取消操作，防止连续点击重复发送请求(例如连续点击2次按钮)
  // removePending(reqData, true);
  // 设置请求的 cancelToken
  config.cancelToken = new axios.CancelToken(c => {
    // pending[reqData] = c;
    allPendingRequestsRecord.push(c);
  });
  return config
}, error => {
  // 请求错误时做些事
  return Promise.reject(error)
})

// 添加响应拦截器
http.interceptors.response.use(response => {
  return response.data && response.data
}, error => {
  return Promise.reject(error)
})

export default http
