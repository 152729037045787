import css from '../css/nav.module.scss'
const navList = [
  { name: '企业新闻', text: 'Company news', id: "135" },
  { name: '行业动态', text: 'Industry News', id: "136" },
  { name: '公告信息', text: 'Announcement', id: "137" },
]


const Navs = (props) => {
  const { navIndex, changeNav } = props

  return (
    <div className={css.box}>
      <div className={css.nav}>
        {
          navList.map(item => (
            <div key={item.id}
              className={navIndex === item.id ? css.active : css.navItem}
              onClick={() => changeNav(item.id)}>
              <div>{item.name}</div>
              <div className={css.text}>{item.text}</div>
              <div className={css.active999}></div>
            </div>
          ))
        }
      </div>
    </div>
  )
}


export default Navs
