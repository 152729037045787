import css from '../services/css/services.module.scss'
import {useState, useEffect} from 'react'
import About0 from "./components/about1.jsx"
import About1 from "./components/about2.jsx"
import About2 from "./components/about3.jsx"
import About3 from "./components/about4.jsx"
import css1 from "./css/about.module.scss"
import imgleft from "../../assets/about/aboutleft.png"

const AboutHx = () => {
  return (
    <>
      <div className={css.bannerTitle}>
        <div className={css.slideInEllipticLeftFwd}>
          <p>
            <span className={css.p1span1}>关于利晶</span>
            <span className={css.p1span2}>ABOUT </span>
          </p>
          <p className={css.p2}></p>
        </div>
        <p className={`${css.p3} ${css.slideInEllipticRightBck}`}>记录点滴进步 书写精彩篇章 利晶与你一同见证</p>
      </div>
      <div className={css1.leftimg}>
        <img src={imgleft} alt=""></img>
      </div>
    </>
  )
}

const HxZhaopin = () => {
  return (
    <>
      <div className={css.bannerTitle}>
        <div className={css.slideInEllipticLeftFwd}>
          <p>
            <span className={css.p1span1}>WELCOME</span>
            <span className={css.p1span2}></span>
          </p>
          <p className={css.p2}></p>
        </div>
        <p className={`${css.p3} ${css.slideInEllipticRightBck}`}>利晶微电子欢迎你</p>
      </div>
      <div className={css1.leftimg}>
        <img src={imgleft} alt=""></img>
      </div>
    </>
  )
}

const Head = (props) => {
  const {navIndex, history, show} = props
  const navList = [
    {name: '企业简介', id: "0"},
    {name: '荣誉资质', id: "1"},
    {name: '招聘英才', id: "2"},
    {name: '联系我们', id: "3"},
  ]

  return (
    <div className={css1.head} style={{
      background: `url(/banner4.png)`
    }}>
      <div className={css1.navs}>
        <div className={css.nav}>
          {
            navList.map(item => (
              <div key={item.id}
                   className={navIndex === item.id ? css.active : css.navItem}
                   onClick={() => {
                     history.push('/about?id=' + item.id)
                   }}>
                <div>{item.name}</div>
              </div>
            ))
          }
        </div>
      </div>
      <div className={css.namesss}>
        {
          show ? <AboutHx></AboutHx> : <HxZhaopin></HxZhaopin>
        }
      </div>
    </div>
  )
}

const About = (props) => {
  const {location, history} = props

  const [navIndex, setNavIndex] = useState("0")
  const [HeaderShow, setHeaderShow] = useState(true) // 控制招聘详情和header

  useEffect(() => {
    const id = location.search ? location.search.split('?id=')[1] : "0"
    setNavIndex(id)
    setHeaderShow(true)
  }, [location])

  return (
    <div>
      <Head navIndex={navIndex} show={HeaderShow} history={history}/>
      <div className={css.top}>
        {[<About0/>, <About1/>, <About2 setHeaderShow={setHeaderShow}/>, <About3/>][navIndex]}
      </div>
    </div>
  )
}

export default About
