import { useHistory, useLocation } from "react-router-dom";
import css from './css/header.module.scss'
import { useViewport } from '../useViewPort'
import HeaderMobile from "./headerMobile";
import phone from '../assets/image/phone.png'
import { useEffect, useState } from "react";

const navObj = {
  '/': 0,
  '/news': 1,
  '/services': 2,
  '/about': 3
}

const Header = () => {
  // 获取路由对象
  let history = useHistory();
  let location = useLocation();
  const [navIndex, setNavIndex] = useState(navObj[location.pathname])
  const [navClassName, setNavClassName] = useState(css.NavSlidePar)
  // 导航列表
  const navs = [
    {
      name: '首页',
      router: '/',
      img: '/nav1.png',
      children: [
        { name: '企业简介', router: '/?id=0' },
        { name: '利晶事业', router: '/?id=1' },
        { name: '新闻动态', router: '/?id=2' },
        { name: '企业现状', router: '/?id=3' },
        { name: '合作伙伴', router: '/?id=4' },
      ]
    },
    {
      name: '新闻动态',
      router: '/news',
      img: '/nav2.png',
      children: [
        { name: '企业新闻', router: '/news?id=135' },
        { name: '行业动态', router: '/news?id=136' },
        { name: '公告信息', router: '/news?id=137' },
      ]
    },
    {
      name: '产品服务',
      router: '/services',
      img: '/nav3.png',
      children: [
        { name: 'MiniLED', router: '/services?id=138' },
        { name: 'MicroLED', router: '/services?id=139' },
        { name: 'ODM产品', router: '/services?id=151' },
        { name: '其他', router: '/services?id=140' },
      ]
    },
    {
      name: '关于利晶',
      router: '/about',
      img: '/nav4.png',
      children: [
        { name: "企业简介", router: '/about?id=0' },
        { name: "荣誉资质", router: '/about?id=1' },
        { name: "招聘英才", router: '/about?id=2' },
        { name: '联系我们', router: '/about?id=3' },
      ]
    }
  ]
  const { width } = useViewport()

  useEffect(() => {
    if (location.pathname.includes('/alldetail/')) {
      setNavIndex(1)
    }
    if (location.pathname.includes('/services')) {
      setNavIndex(2)
    }
    if (location.pathname.includes('/about')) {
      setNavIndex(3)
    }
    if(location.pathname !== '/'){
      window.scrollTo(0, 0)
    }
  }, [location])

  return (
    <>{width > 875 ?
      <div className={css.header}>
        <img src="/api/uploadFile/upload/b9c2f195-b65f-47d1-b0cd-000e6e52590a.png" alt="logo"  style={{width:'390px'}}/>
        <div className={css.navs}>
          {
            navs.map((item, index) => (
              <div className={navClassName} key={index}>
                <div className={location.pathname === item.router ? css.active : css.item}
                  style={{ fontWeight: location.pathname.includes('/alldetail/') && item.router === '/news' ? 'bold' : '' }}
                  onMouseLeave={() => {
                    setNavClassName(css.NavSlidePar)
                  }}
                  onClick={() => {
                    setNavClassName('temp')
                    setNavIndex(navObj[item.router])
                    history.push(item.router)
                  }}>
                  {item.name}
                </div>
                <HoverItem item={item} setNavIndex={setNavIndex} setNavClassName={setNavClassName}
                  navClassName={navClassName} />
              </div>
            ))
          }
          <div className={css.yidong} style={{ left: 138 * navIndex + 'px' }}></div>
        </div>
        <div className={css.icons}>
          <img src={phone} alt="" />
          <div className={css.phoneright}>
              <div className={css.title}>服&nbsp;务&nbsp;热&nbsp;线</div>
              <div className={css.subtitle}>0510-80908888</div>
          </div>
        </div>
      </div> : <HeaderMobile />
    }</>
  )
}


const HoverItem = ({ item, setNavIndex, setNavClassName, navClassName }) => {
  let history = useHistory();

  return (
    <div className={css.itemBoxs}>
      <div className={css.hoverItem}>
        <img src={item.img} alt="" style={{ width: '500px', height: '250px', display: 'block' }} />
        <div className={css.itemBox}>
          {
            item.children.map((val, index) => (
              <div key={index} className={css.itemBoxItem}
                onMouseLeave={() => {
                  setNavClassName(css.NavSlidePar)
                }}
                onClick={() => {
                  setNavIndex(navObj[item.router])
                  setNavClassName('temp')
                  history.push(val.router)
                }}>
                {val.name}
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}


export default Header
