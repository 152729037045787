import css3 from "../css/about3.module.scss"
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper.scss';
import img1 from "../../../assets/about/ui.png"
import img2 from "../../../assets/about/web.png"
import Header from "./header"
import {useEffect, useState} from "react"
import {ZpwrapXQ, about4} from "../../../axios/index.js"
import {Breadcrumb,Button,Table } from "antd";

const { Column } = Table;

// 招聘页
const Zpwrap = (props) => {
    const {setShowSwiper} = props
    const [article, setArticle] = useState([])
    useEffect(() => {
        ZpwrapXQ(props.id).then(res => {
            const result = res.data
            setArticle(result)
        })
    }, [props])
    return (
        <div className={css3.zpwrap}>
            <div className={css3.zptit}>
                <div className={css3.zptitwrap}>
                    <div className={css3.zptitle}>
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">
                                首页
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <span onClick={() => setShowSwiper(true)}>招聘英才</span>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>{article?.title} </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div className={css3.name}>
                        <div className={css3.namebox}>
                            <span>{article?.title}</span>
                            <span>1</span>
                            <span>人</span>
                        </div>
                        <button className={css3.zpbutton}>申请</button>
                    </div>
                    <p className={css3.zpaddress}>
                        <span>{article?.subTitle}</span>
                        <span>{(article?.updateDate)?.slice(0, 11)}</span>
                    </p>
                </div>
            </div>
            <div className={css3.zpyq}>
                <h2 className={css3.zwms}>职位描述</h2>
                <p className={css3.msnr}
                   dangerouslySetInnerHTML={{__html: article?.marks}}
                >
                </p>
                <h2 className={css3.gwyq}>岗位要求</h2>
                <p className={css3.msnr}
                   dangerouslySetInnerHTML={{__html: article?.content}}
                >
                </p>
            </div>
        </div>
    )
}
// 招聘轮播
const About3 = (props) => {
    const [swiperData, setSwiperData] = useState([])
    const [swiper, setSwiper] = useState(null)
    const [id, setId] = useState(0)
    const [navIndex, setNavIndex] = useState(0)
    const [showSwiper, setShowSwiper] = useState(true)

    useEffect(() => {
        about4().then(res => {
            setSwiperData(res.data)
        })
    }, [])


    const {setHeaderShow} = props
    useEffect(() => {
        setHeaderShow(showSwiper)
    }, [setHeaderShow, showSwiper])

    return (
        <>
            {
                showSwiper ? (

                        <div className={css3.about3}>
                            <Button type="primary" onClick={()=>{
                                setNavIndex(navIndex === 0 ? 1 : 0)
                            }}>切换列表/视图</Button>
                            <Header ctit="招聘英才" etit="Talent recruitment"/>
                            {
                                navIndex === 0 && swiperData.length > 1 ? (<div className={css3.swiperWrap}>
                                    <Swiper
                                        slidesPerView={3}
                                        spaceBetween={20}
                                        autoplay={true} loop={true}
                                        onSwiper={swiper => setSwiper(swiper)}
                                    >
                                        {
                                            swiperData.map((item, index) => (
                                                <SwiperSlide key={index}>
                                                    <div onClick={() => {
                                                        setId(item.id)
                                                        setShowSwiper(false)
                                                    }}>
                                                        <div className={css3.swiper1}>
                                                            <img src={index % 2 ? img1 : img2} alt=""/>
                                                        </div>
                                                        <div className={css3.swipername}>{item.title}</div>
                                                    </div>
                                                </SwiperSlide>
                                            ))
                                        }
                                    </Swiper>
                                    <div className={css3.navBoxleft}>
                                        <div className={css3.hoverQY}>
                                            <div className={css3.navBoxleft1} onClick={() => swiper.slidePrev()}></div>
                                        </div>
                                        <div className={css3.hoverQY}>
                                            <div className={css3.navBoxleft2} onClick={() => swiper.slideNext()}></div>
                                        </div>
                                    </div>
                                </div>) : (
                                    <Table dataSource={swiperData}>
                                        <Column
                                            title="岗位名称"
                                            key="title"
                                            render={(text, record) => (
                                                <div className={css3.jiacu}>{record.title}</div>
                                            )}
                                        />
                                        <Column title="岗位要求" dataIndex="text" key="text"/>
                                        <Column title="发布时间" dataIndex="updateDate" key="updateDate" width="200px"/>
                                        <Column
                                            width="100px"
                                            title="操作"
                                            key="id"
                                            render={(text, record) => (
                                                <a onClick={() => {
                                                    setId(record.id)
                                                    setShowSwiper(false)
                                                }}>查看详情</a>
                                            )}
                                        />
                                    </Table>
                                )
                            }
                        </div>
                    )
                    : (
                        <Zpwrap id={id} setShowSwiper={setShowSwiper}/>
                    )
            }

        </>
    )
}

export default About3
