import css from '../css/banner.module.scss'
import {articleList} from '../../../axios'
import {useEffect, useState} from 'react'
import {useHistory} from "react-router-dom";
// swiper
import SwiperCore, {Autoplay, Pagination, Navigation} from 'swiper'
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/components/navigation/navigation.scss';
SwiperCore.use([Autoplay, Pagination, Navigation]);

// 新闻公告
const NewsList = () => {
  const [list, setList] = useState([])
  const [swiper, setSwiper] = useState(null)
  const history = useHistory()

  useEffect(() => {
    articleList({
      channelId: 135,
      limit: 5,
      page: 1
    }).then(res => {
      setList(res.data)
    })
  }, [])

  return (
    <div className={css.newsList}>
        <div className={css.newsListBox}>
          <div className={css.title}>最新动态</div>
          <div className={css.news}>
            <Swiper autoplay={true} loop={true} direction="vertical"
                    onSwiper={swiper => setSwiper(swiper)}>
              {
                list.map((item, index) => (
                  <SwiperSlide key={index}>
                    <div onClick={() => history.push('/alldetail/' + item.id)}>{item.title}</div>
                  </SwiperSlide>
                ))
              }
            </Swiper>
          </div>
          <div className={css.navBoxleft}>
            <div className={css.navBoxleft1} onClick={() => swiper.slidePrev()}></div>
            <div className={css.navBoxleft2} onClick={() => swiper.slideNext()}></div>
          </div>
        </div>
    </div>
  )
}

// banner
const BannerList = () => {
  const [swiper, setSwiper] = useState(null)
  const [swiperList, setSwiperList] = useState([])

  useEffect(() => {
    articleList({
      channelId: 147,
      limit: 5,
      page: 1
    }).then(res => {
      console.log(res);
      setSwiperList(res.data)
    })
  }, [])

  return (
    <div className={css.BannerList}>
      <Swiper autoplay loop onSwiper={swiper => setSwiper(swiper)} pagination={{
        clickable: true,
        bulletClass: 'banner1',
        bulletActiveClass: 'banner1-active',
        modifierClass: 'banner1-',
      }}>
        {
          
          
          swiperList.map((item, index) => (
            <SwiperSlide key={index}>
              <img src={'/api' + item.showPic} alt="" className={css.img}/>
            </SwiperSlide>
          ))
        }
      </Swiper>
      <div className={css.navBoxleft3}>
        <div className={css.navBoxleft4} onClick={() => swiper.slidePrev()}></div>
        <div className={css.navBoxleft5} onClick={() => swiper.slideNext()}></div>
      </div>
    </div>
  )
}

// banner组件
const Banner = () => {
  return (
    <div className={css.banners}>
      <BannerList/>
      <NewsList/>
    </div>
  )
}

export default Banner
