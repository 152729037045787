import http from './axios'

export const getSiteInfo = () => {
    return http({
        url: '/admin/system/site/getSiteInfo',
        method: 'GET'
    })
}

export const channelList = (channelId = '') => {
    return http({
        url: '/admin/blogChannel/channelList?channelId=' + channelId,
        method: 'GET',
    })
}

export const articleList = (params) => {
    return http({
        url: '/admin/blogArticle/articleList',
        method: 'GET',
        params
    })
}


export const articleInfo = (id) => {
    return http({
        url: '/admin/blogArticle/articleInfo/' + id,
        method: 'GET'
    })
}

export const about1 = () => {
    return http({
        url: '/admin/blogArticle/articleList?channelId=142',
        method: 'GET'
    })
}
export const about2 = () => {
    return http({
        url: '/admin/blogArticle/articleList?channelId=143',
        method: 'GET'
    })
}
export const about3 = () => {
    return http({
        url: '/admin/blogArticle/articleList?channelId=145',
        method: 'GET'
    })
}
export const about4 = () => {
    return http({
        url: '/admin/blogArticle/articleList?channelId=145&limit=99',
        method: 'GET'
    })
}
export const ZpwrapXQ = (id) => {
    return http({
        url: 'admin/blogArticle/articleInfo/' + id,
        method: 'GET'
    })
}

export const add = (params) => {
    return http({
        url: 'admin/system/user/client/add',
        method: 'POST',
        params
    })
}
