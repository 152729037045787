import css from '../css/qiyejianjie.module.scss'
import iconLeft from '../../../assets/image/iconLeft.png'
import iconRight from '../../../assets/image/iconRight.png';

// 产品及服务
const Qiyejianjie = () => {
  return (
    <div className={css.qiyejianjie} id='offsetTop0'>
      <div className={css.title}>
        <div className={css.titles}>
          <img src={iconLeft} alt="iconLeft" className={css.iconLeft}/>
          <div id="div0" style={{margin: '0 10px 10px'}}>企业简介</div>
          <img src={iconRight} alt="iconRight" className={css.iconRight}/>
        </div>
        <div className={css.titleBottom}>
          <div className={css.b1}></div>
          <div className={css.b2}></div>
        </div>
      </div>

      <div className={css.main}>
        <video src="./swiper.mp4" className={css.video} loop autoPlay={true} muted ></video>
        <div className={css.texts}>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span>利晶微电子技术（江苏）有限公司</span>
            （以下简称利晶微电子）于2019年12月25日利亚德光电股份有限公司（以下简称利亚德）、台湾晶元光电股份有利晶微电子技术（江苏）有限公司（以下简称利晶微电子）
            于2019年12月25日利亚德光电股份有限公司（以下简称利亚德）、台湾晶元光电股份有限公司（以下简称台湾晶电）与无锡市委市政府签署战略投资协议后开始筹建。公司于2020年3月26日注册完成，注册资金3亿元。
            一期项目战略投资13亿元，用以建设Mini LED和Micro LED全球首个显示量产基地，推动Mini LED和Micro LED显示技术在研发、生产和应用领域的发展，进一步满足全球LED市场高速增长的需求。
            一期项目包含厂房改造、智能车间搭建、MicroLED研究院建立、重点实验室建设等，项目中总改造面积18500平方米，其中厂房改造面积13500平方米，包含智能车间建设、净化无尘车间建设等。
            <span>在地方政府的帮助和支持下，公司运行已取得阶段性成果，于2020年10月底正式投产。从战略合作意向达成到正式投产仅用了不到10个月时间，刷新了“梁溪速度”、创造了“无锡制造”新纪录和“无锡速度”！同时，也创造了三个全球第一！</span>
          </p>
        </div>
      </div>
    </div>
  )
}

export default Qiyejianjie
