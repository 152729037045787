import Header from "./header"
import css from "../css/about.module.scss"
import img411 from "../../../assets/about/about4/dz.png"
import img412 from "../../../assets/about/about4/lx.png"
import img413 from "../../../assets/about/about4/hxyx.png"
import Map from "./map"
import {add} from '../../../axios/index'
import {useState} from "react";
import { message } from 'antd';
const formation = {
    ctit: '联系我们',
    etit: 'Contact us',
    text: '利晶联系方式'
}
const list = [
    { img: img413, tit1: '利晶地址', tit2: '无锡市梁溪区金山北工业园金山四支路9-2、9-3' },
    { img: img411, tit1: '联系我们', tit2: '0510-80908888' },
    { img: img412, tit1: '利晶邮箱', tit2: 'leadstar@leadstarmcd.com' },
]


// 提交数据
const FromData = ()=>{
  const [company, setCompany] = useState('')
  const [clientPhone, setClientPhone] = useState('')
  const [clientName, setClientName] = useState('')
  const [clientEmail, setClientEmail] = useState('')
  const [comment, setClientComment] = useState('')
  const [code, setCode] = useState('')
  const [verifyCode, setVerifyCode] = useState('/api/admin/genCaptcha?t=2021')

  function resetVerifyCode(){
    let url = "/api/admin/genCaptcha?t="+Math.random()
     setVerifyCode(url)
  }

  function resetForm(){
    setCompany("")
    setClientPhone("")
    setClientName("")
    setClientEmail("")
    setClientComment("")
    setCode("")
  }
  

  return (
    <div className={css.inputwrap}>
      <input placeholder="公司" value={company} onInput={e => setCompany(e.target.value)} className={css.input}/>
      <input placeholder="姓名" value={clientName} onInput={e => setClientName(e.target.value)}
             className={css.input}/>
      <input placeholder="电话" value={clientPhone} onInput={e => setClientPhone(e.target.value)}
             className={css.input}/>
      <input placeholder="邮箱" value={clientEmail} onInput={e => setClientEmail(e.target.value)}
             className={css.input}/>
      <textarea rows="5" cols="33" placeholder="留言" value={comment}
                onInput={e => setClientComment(e.target.value)} className={css.textarea}/>
      <input placeholder="验证码" value={code} onInput={e => setCode(e.target.value)}
             className={css.input} style={{width:400}}/>

      <img className={css.img}  src={verifyCode} onClick={ ()=>{
        resetVerifyCode()
      }}/>
      <button className={css.button} onClick={()=>{
        // 提交中
        const key = 'updatable'
        message.loading({ content: '提交中...', key, className: 'custom-class',});
        // 提交数据
        const formdata = {company,clientPhone,clientName,clientEmail,comment,code}
        // 打印提交数据
        console.log(formdata)
        // post 提交
        add(formdata).then(res=>{
          resetVerifyCode()
          // 成功
          if(res.code = 200){
            if(res.success){
              message.success({ content: '提交成功!',key, duration: 2, className: 'custom-class' });
              resetForm()
            }else{
              message.error({ content: '提交失败! '+res.message,key, duration: 2 ,className: 'custom-class'});
            }

          }else{
          }

          // // 模拟成功
          // setTimeout(() => {
          //   message.success({ content: '提交成功!', key, duration: 2, className: 'custom-class', });
          // }, 2000);
        })
      }}>提交</button>
    </div>
  )
}

const About4 = () => {
    return (
        <>
            <Header {...formation}></Header>
            <div className={css.about4}>
                <div className={css.con41wrap}>
                    {
                        list.map((item, index) => (
                            <div className={css.con41} key={index}>
                                <dl style={{
                                    textAlign: 'center'
                                }}>
                                    <dt style={{
                                        marginTop: 45,
                                        marginBottom: 28,
                                    }}>
                                        <img src={item.img} alt=""></img>
                                    </dt>
                                    <dd style={{
                                        marginTop: 45,
                                        marginBottom: 28,
                                    }}>{item.tit1}</dd>
                                    <dd>{item.tit2}</dd>
                                </dl>
                            </div>
                        ))
                    }

                </div>

            </div>
          <div className={css.con42wrap}>
            <div className={css.con42h1}>
              <p className={css.p1}>给我们留言</p>
              <p className={css.p2}>您的任何疑问或建议，都可通过留言反馈给我们</p>
            </div>
            <FromData/>
          </div>
          <Map></Map>
        </>
    )
}
export default About4
