import styles from './css/headerMobile.module.scss'
import Menu from '../assets/news/menu.png'
import logo from '../assets/logo.png'
import Right from '../assets/news/right.png'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'

const navs = [
  {name: '首页', router: '/'},
  {name: '新闻动态', router: '/news'},
  {name: '产品服务', router: '/services'},
  {name: '关于利晶', router: '/about'},
]

function HeaderMobile () {
  const [show, setShow] = useState(false)
  const history = useHistory()
  return (
    <>
      <div className={styles.container}>
        <img src={logo} alt="logo"/>
        <img src={Menu} alt="" onClick={() => setShow(!show)} />
      </div>
      {show ? navs.map(nav => (
        <div className={styles.item} key={nav.router} onClick={() => {
          history.push(nav.router)
          setShow(false)
        }}>
          <span>{nav.name}</span>
          <img src={Right} alt=""/>
        </div>
      )) : null}
    </>
  )
}

export default HeaderMobile
