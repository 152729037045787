import { useState, useEffect } from 'react'
import Navs from "./components/navs";
import NewsList from "./components/newsList";
import { useViewport } from '../../useViewPort'
import NewsListBobile from './components/newsListMobile';
import NavsMobile from './components/navsMobile';
import { articleList } from '../../axios'
import NavTitle from "./components/navTitle"
import css from './css/nav.module.scss'
import { message } from 'antd';

const News = (props) => {
  let {location} = props

  const [navIndex, setNavIndex] = useState("135")
  const [page, setPage] = useState(1)
  const [list, setList] = useState([])
  const [swiperList, setSwiperList] = useState([])
  const { width } = useViewport()

  const changeNav = navIndex => {
    setPage(1)
    setNavIndex(navIndex)
    articleList({
      channelId: navIndex,
      limit: 5,
      page: 1
    }).then(res => {
      setList(res.data)
      setSwiperList(res.data)
    })
  }

  const addList = () =>{
    setPage(page + 1)
    articleList({
      channelId: navIndex,
      limit: 5,
      page: page
    }).then(res => {
      if(res.data.length < 5){
        message.warning("没有更多了");
      }else{
        setList([...list,...res.data])
      }
    })
  }

  useEffect(() => {
    const id = location.search ? location.search.split('?id=')[1] : "135"
    changeNav(id)
  }, [location])

  return (
    <div>
      <NavTitle />
      { width > 875 ?
        <Navs navIndex={navIndex} changeNav={changeNav} /> :
        <NavsMobile navIndex={navIndex} changeNav={changeNav} />
      }
      { width > 875 ?
        (
          <div>
            <NewsList list={list} swiperList={swiperList} navIndex={navIndex}/>
            <div className={css.btns} onClick={()=>{
              addList()
            }}>查看更多</div>
          </div>
        ) :
        <NewsListBobile list={list} />
      }
    </div>
  )
}

export default News
