import Banner from "./components/banner";
import Qiyejianjie from "./components/qiyejianjie";
import QiyejianjieMobile from "./components/qiyejianjieMobile";
import News from "./components/news";
import Shiye from "./components/shiye";
import ShiyeMobile from './components/shiyeMobile'
import NewsMobile from "./components/newsMobile";
import Fenge from "./components/fenge";
import Hezuo from "./components/hezuo";
import {useViewport,AnimationFrames} from "../../useViewPort"
import {useLocation, useHistory} from "react-router-dom";
import {useEffect} from "react";

const Home = () => {
  let location = useLocation();
  let history = useHistory();

  useEffect(() => {
    if (location.search) {
      const index = location.search.split('?id=')[1]
      const dom = document.getElementById('offsetTop' + index)
      let num = dom.offsetTop - 150
      if (index === "2") {
        num += 80
      }
      // num 要移动到的位置
      AnimationFrames(num,20)
    }
  }, [location,history])
  const {width} = useViewport()

  return (
    <>
      <Banner/>
      {width > 1200 ? <Qiyejianjie/> : <QiyejianjieMobile/>}
      {width > 1200 ? <Shiye/> : <ShiyeMobile/>}
      {width > 1200 ? <News/> : <NewsMobile/>}
      {width > 1200 ? <Fenge/> : null}
      {width > 1200 ? <Hezuo/> : null}
    </>
  )
}

export default Home;
