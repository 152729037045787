import css from "../css/about.module.scss"
import { useEffect, useState } from "react"
import { about2 } from "../../../axios/index.js"
import Header from "./header";
const About2 = () => {
    const [article, setArticle] = useState([])
    useEffect(() => {
        about2().then(res => {
            const temp = res.data[0].content
            setArticle(temp)
        })
    }, [])
    return (
      <>
          <Header ctit="荣誉资质" etit="Honor"/>
          <div className={css.con1} dangerouslySetInnerHTML={{ __html: article }}></div>
      </>
    )
}
export default About2
